// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bookmarktopia-js": () => import("./../src/pages/bookmarktopia.js" /* webpackChunkName: "component---src-pages-bookmarktopia-js" */),
  "component---src-pages-detailspro-js": () => import("./../src/pages/detailspro.js" /* webpackChunkName: "component---src-pages-detailspro-js" */),
  "component---src-pages-easyedittext-js": () => import("./../src/pages/easyedittext.js" /* webpackChunkName: "component---src-pages-easyedittext-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-layer-outer-js": () => import("./../src/pages/layer_outer.js" /* webpackChunkName: "component---src-pages-layer-outer-js" */)
}

